/* eslint-disable linebreak-style */
import Communication from './lib/Communication';
import PluginContainer from './lib/PluginContainer';
/**
 * Experimential plugin manager.
 *
 * This is an experimental plugin manager for the Professional Client to demonstrate the concept of a safe,
 * in-browser plugin system using native resources as protection (iframes),
 *
 *
 *
 */
export default class PluginLoader {
    plugins;
    _config;
    _sdk;

    // This is a fake customer UUID because we don't have that yet on the platform.
    // We use this below to resolve the private plugins of this customer.
    //_customerId = '1706cefb-b560-4e4d-8f8b';

    // This is a fake plugin repository endpoint which currently points to the same server.
    //_repositoryUrl = 'https://localhost:8001';

    _communication;
    _pluginContainerClass;
    _containers = new Map();
    _customerId : string;
    _repositoryUrl: string;
    get containers() {
      return this._containers;
    }

    get config() {
      return this._config;
    }

    constructor(plugins, config, sdk) {
      this._config = config;
      this._sdk = sdk;

      // Prepare the config by exploding the provided plugin names into their respective parts.
      // A plugin may be defined as "private/my-plugin/next", where "private" is the scope,
      // "my-plugin" is the name and "next" is the desired version. This naming is completely
      // compatible to the existing configuration.

      this._customerId = this._config.customerId;

      this._repositoryUrl = this._config.urls.packageRepository;

      this.plugins = plugins.map(plugin => {

        const [scope, name, version] = plugin.name.split('/');

        let resolvedName = name;

        //https://4com.kanbanize.com/ctrl_board/5/cards/43401/details/
        if (scope === 'private') {
          resolvedName = `${this._customerId}-${name}`;
        }

        return {
          config   : plugin.config || {},
          scope,
          name,
          version,
          namespace: `plugins.${scope}.${name}`,
          url      : `${this._repositoryUrl}/${scope}/${resolvedName}/${version}/package.js`,
        };
      });
    }

    async run() {
      this._communication = new Communication(this);

      console.log('New plugin loader runs : ' + this.plugins.map(p => p.name)); //eslint-disable-line

      await this._constructAllPlugins();
      // At this point all plugins have been constructed, now they can be run.
      await this._runAllPlugins();
    }

    /**
     * Garbage collection.
     *
     */
    stop() {
      this._communication.stop();

      // Remove all created containers.
      [...this._containers.values()].forEach((container, i) => {
        container.destroy();
        delete this._containers[i];
      });
    }

    /**
     * This method will cycle through the configured list of plugins and creates a dedicated plugin container
     * for each. This involves bringing up a new iframe filled with the embed-api and the specific plugin and
     * waiting for a first message as sign of (hopefully) intelligent life. Because we must guarantee availability
     * of all plugins once we actually run them, we need to block execution while the plugins are created. In
     * order to do this, we receive a promise from each container that is resolved with the first ping of the
     * plugin. Note how we use Promise.all to await the creation of all plugins, this reduces the startup time
     * because each plugin is potentially created in a separated thread.
     *
     */
    async _constructAllPlugins() {
      const constructionPromises = [];

      this.plugins.forEach(plugin => {
        this.containers.set(plugin.namespace, new PluginContainer(plugin, this._sdk, this));
        constructionPromises.push(this.containers.get(plugin.namespace).constructionPromise);
      });
      await Promise.all(constructionPromises);
    }

    /**
     * After all plugins have been created in a dedicated container and thread, this method cycles through
     * them and invokes their respective .run() method. This is the point where plugins are finally allowed
     * to communicate with each other and the core.
     *
     */
    async _runAllPlugins() {
      let shuffledPluginList = this.plugins;
      // Shuffle the list of plugins before running them to discourage dependence on any order.
      // Any plugin must be ready for interaction after construction. Consequently the order of running
      // must never be important for any plugin to function properly. If a plugin must be run before
      // or after a certain other plugin, that is considered an anti-pattern.
      //
      // This is because otherwise we would have to block the main thread two times for each plugin added
      // to the Client: once for construction, once for running. By disallowing run order reliance we
      // minimize the impact of plugins on the start up performance of the Client.
      for (let i = shuffledPluginList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledPluginList[i], shuffledPluginList[j]] = [shuffledPluginList[j], shuffledPluginList[i]];
      }

      shuffledPluginList.forEach(plugin => this.containers.get(plugin.namespace).run());
    }
}