export const resolvePath = async (initialObject, path: string[] | string = '' ) => {
  if (Array.isArray(path)) {
    // Turn the path back into a string to that we can apply our own explosion later on.
    path = path.join('.');
  }

  // Split the path by dot and [] but mark the [] entries so that we know to handle them different
  // later on. This is because everything that is accessed using the .get() function must be declared
  // with the array syntax in the path. This way it is possible to even access deeply nested elements.
  // Note that the while the path is splitted on dots, parts within brackets will be left intact to
  // not break namespaced parameters. This way it is possible to access elements with dots in their
  // name, like compositions:
  //
  // sdk.get('plugins.professional-client.ui.compositions[core.modules].entries');
  //
  // If we would split "core.modules" up here as well, things would break badly.
  path = path
    .replace(/\[/g, '[^')
    .split(/\.(?![^[]*\])|[[\]]/g)
    .filter(Boolean);

  for (let currentEntry of path) {
    if (currentEntry.includes('^')) {
      // This originally was an entry using the array syntax.
      initialObject = initialObject.get(currentEntry.slice(1));
    } else {
      // This is a simple element.
      initialObject = initialObject[currentEntry];
    }

    if (initialObject instanceof Promise) {
      // Whatever initialObject currently is, it seems to by async. In order for the path
      // to actually resolve to what the initiating party expects here, we'll first have
      // to await the returned promise to resolve before we can dig any deeper.
      initialObject = await initialObject;
    }
  }

  return initialObject;
};