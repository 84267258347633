import { createProxy } from '@multichannel/sdk/src/api/Proxy';
//import MultichannelSdk from '../api';
import CONSTANTS, { PLUGIN_DEPRECATED } from '@multichannel/sdk/src/api/constants';
import Compareable from '@multichannel/sdk/src/Comparable';
import PluginLoader from './api/PluginLoader/PluginLoader';
import Actions from '@core/actions';
/**
 * A list of packages and package configuration that should be loaded for every (pcweb) customer.
 *
 * @todo Move this away from here.
 * @type {Array}
 */
const defaultBundle = [{ name: 'pcweb/gui/current' }];

//const SystemJS = window.System;

interface PluginDefinition {
  config: {};
  module: any;
  name: string;
  url?: string;
}

export class Plugins extends Compareable {

  private _currentBundle;
  private _isInitiated;
  private _logger;
  private _map;
  private _pluginPaths;
  private _proxy;
  private _vier;

  constructor() {
    super();

    this._map = new Map();
    this._pluginPaths = {};
  }

  public get currentBundle() {
    return this._currentBundle;
  }

  public setApiInstance = (api:any) => {
    this._proxy = createProxy(this);
    this._vier = api;
    //this._logger = this._vier.debug('api.plugins');

    Actions.register('core.plugins.update-plugin-config', async updatePlugin =>
      this._updatePluginConfig(updatePlugin),
    );

    //window.SystemJS.config({ baseURL: this._vier.config.urls.packageRepository + '/' });

    return this._proxy;
  }

  public setLogger(logger: any): void {
    this._logger = logger;
  }

  async _updatePluginConfig(pluginUpdate: any) {
    const { plugin_id, plugin_config } = pluginUpdate;
    const plugin = this.get(plugin_id);

    this._vier.logger.trace(`Updating plugin ${plugin_id}`, { pluginUpdate });

    if (plugin) {
      try {
        // step 1: update api plugin config
        this._vier.updatePluginConfig(plugin_id, plugin_config);

        // step 2: stop plugin
        await plugin.stop();

        // step 3: delete plugin
        this._map.delete(plugin_id);

        // step 4: get the plugin config
        const pluginDefinition = this._currentBundle.find(
          plugin =>
            plugin.name.includes(plugin_id) || plugin.config?.plugin?.name.includes(plugin_id),
        );

        // step 5: get the plugin module
        const pluginModule = pluginDefinition?.module || pluginDefinition?.config?.plugin?.module;

        // step 6: reconstruct plugin if possible
        const constructedPlugin = new pluginModule(plugin_config, this._vier);
        this._map.set(plugin_id, constructedPlugin);

        // step 7 : run the plugin
        constructedPlugin.run(this._vier);
      } catch (e) {
        this._logger.error(`Error while updating plugin config for ${plugin_id}`, {
          pluginUpdate,
          e,
        });
      }
    }
  }

  /**
   * Exposes a single module as a dynamic component.
   *
   * @param {string} name
   * @param component
   * @private
   */
  _exposeModule(name, component) {
    //@ts-ignore
    window.System.registerDynamic(name, [], true, (require, exports, module) => {
      module.exports = component;
    });
  }

  /**
   * Loads and initiates all plugins provided via pluginList.
   *
   * @private
   * @param {PackageDefinition[]} pluginList
   *
   * @todo Packages should not touch global scope like this.
   *       More so in a perfect world they would be completely isolated from the actual vier scope and document.
   *       This should be solved before we allow external packages to the system.
   */
  async _loadAndRunPlugins(pluginList: PluginDefinition[]) {
    this._logger.traceCall('_loadAndRunPlugins', { packageList: pluginList });

    // Load all packages in parallel.
    let pluginDefinitions = await Promise.all(
      pluginList
        .sort((a, b) =>
          JSON.stringify(a).includes('pcweb/gui')
            ? 1
            : JSON.stringify(b).includes('pcweb/gui')
              ? -1
              : 0,
        )
        .map(pluginDefinition => this._loadPluginFile(pluginDefinition)),
    );
    const addedPlugins = [];

    // remove deprecated plugins from plugin list
    pluginDefinitions = pluginDefinitions.filter(plugin => {
      const pluginName = plugin.name.substring(0, plugin.name.lastIndexOf('/')) || '';
      if (PLUGIN_DEPRECATED.includes(pluginName)) {
        this._logger.warning('Deprecated plugin found and removed', { pluginName });
        return false;
      }
      return true;
    });

    for (const pluginDefinition of pluginDefinitions) {
      this._logger.trace('Creating new instance of plugin', pluginDefinition.name);
      try {
        if (pluginDefinition.module) {
          if (pluginDefinition.module.default) {
            let module = null;
            try {
              module = new pluginDefinition.module.default(pluginDefinition.config || {}, this._vier);
            } catch (e) {
              module = new pluginDefinition.module.default.default(pluginDefinition.config || {}, this._vier);
            }
            const simplifiedName = pluginDefinition.name.substring(
              0,
              pluginDefinition.name.lastIndexOf('/'),
            );

            this._map.set(simplifiedName, module);

            addedPlugins.push(simplifiedName);

            if (pluginDefinition.url) {
              this._pluginPaths[simplifiedName] = pluginDefinition.url.substring(
                0,
                pluginDefinition.url.lastIndexOf('/') + 1,
              );
            } else {
              this._pluginPaths[simplifiedName] =
              this._vier.config.urls.packageRepository + '/' + pluginDefinition.name + '/';
            }
          }
        }
      } catch (error) {
        //Allows better debugging of errors within plugins while constructing
        if(process.env.NODE_ENV === 'development'){
          // eslint-disable-next-line
          console.error(error);
        }
        this._logger.error('Could not create an instance of plugin', {
          pluginName: pluginDefinition.name,
          error,
        });
      }
    }

    this._vier.logger.trace(`Starting ${addedPlugins.length} packages`);

    for (const pluginName of addedPlugins) {
      const timeout = new Promise((_resolve, reject) => {
        let id = setTimeout(() => {
          clearTimeout(id);
          reject('Timed out while loading plugin');
        }, CONSTANTS.PLUGIN_TIMEOUT);
      });

      await Promise.race([this._map.get(pluginName).run(this._vier), timeout]).catch(error => {
        this._map.delete(pluginName);
        this._logger.error('Could not run the plugin', {
          pluginName,
          error,
        });
      });
    }
  }

  /**
   * Loads the static resource file for a given package.
   *
   * @param {{}} pluginDefinition - The definition of the package to be loaded
   */
  async _loadPluginFile(pluginDefinition): Promise<PluginDefinition> {
    this._logger.traceCall('_loadPluginFile', pluginDefinition);
    let module;
    const simplifiedName = pluginDefinition.name.substring(0, pluginDefinition.name.lastIndexOf('/'));

    if (pluginDefinition.name === 'multichannel/development-plugin-container') {
      // @todo move this logic into the actual "multichannel/development-plugin-container" plugin.
      pluginDefinition = pluginDefinition.config?.plugin || {};

      // try to load package by name which is resolved to url in SystemJS import map
      module = await this._tryLoadPackageByNameOrUrl('@' + simplifiedName);

      if (!module) {
        // try to load package by url configured in platform-config
        module = await this._tryLoadPackageByNameOrUrl(pluginDefinition.url);
      }
    } else {
      const packageFile = `/package.min.js?${this._vier.buildInfo.buildTime}`;
      //first of try to load the package via import map
      module = await this._tryLoadPackageByNameOrUrl('@' + simplifiedName);
      if (!module) {
        //if that doesnt work, to load package by built url
        module = await this._tryLoadPackageByNameOrUrl(this._vier.config.urls.packageRepository + '/' + pluginDefinition.name + packageFile);
      }
    }

    if (module) {
      if (module.default) {
        pluginDefinition.module = module.default;
      }
    } else {
      pluginDefinition.module = null;
    }
    return pluginDefinition;
  }

  async _tryLoadPackageByNameOrUrl(str: string) {
    let result;
    try {
      //@ts-ignore
      result = await window.System.import(str);
      this._logger.trace('Loaded package file from server', {
        plugin: str,
      });
    } catch (error) {
      result = null;
      this._logger.error('Could not load package', {
        plugin: str,
        error : error
      });
    }
    return result;
  }

  /**
   * Exposes the 4.js api and all registered plugins as dynamics modules.
   *
   * @param {[]} packageList
   * @private
   */
  _registerModules() {
    this._exposeModule('@multichannel/sdk', this._vier);
    this._exposeModule('@multichannel/sdk/auth', this._vier.auth);
    this._exposeModule('@multichannel/sdk/calls', this._vier.calls);
    this._exposeModule('@multichannel/sdk/workItems', this._vier.workItems);
    this._exposeModule('@multichannel/sdk/events', this._vier.events);
    this._exposeModule('@multichannel/sdk/groups', this._vier.groups);
    this._exposeModule('@multichannel/sdk/language', this._vier.language);
    this._exposeModule('@multichannel/sdk/storage', this._vier.storage);
    this._exposeModule('@multichannel/sdk/plugins', this._vier.plugins);
    this._exposeModule('@multichannel/sdk/onlineMonitor', this._vier.onlineMonitor);
  }

  buildResourceUrl(pluginName, resourcePath) {
    return this.getPluginBasePath(pluginName) + resourcePath;
  }

  /**
   *
   * @param {String} pluginName
   */
  get(pluginName) {
    return this._map.get(pluginName);
  }

  /**
   *
   * @param {String} pluginName
   */
  async getAsync(pluginName) {

    if (this._map.has(pluginName)) {
      return this._map.get(pluginName);
    }

    const tryConstructPlugin = (Plugin: any) => {
      let result;
      try {
        result = new Plugin.default.default({}, this._vier);
      } catch (error) {
        this._logger.error('Could not construct file of plugin', { pluginName });
        result = null;
      }
      return result;
    };

    const module = await this._tryLoadPackageByNameOrUrl('@' + pluginName);
    if (!module) return null;

    const plugin = tryConstructPlugin(module);
    if (!plugin) return null;

    this._map.set(pluginName, plugin);

    let result;
    const timeout = new Promise((_, reject) => {
      let id = setTimeout(() => {
        clearTimeout(id);
        reject();
      }, CONSTANTS.PLUGIN_TIMEOUT);
    });
    await Promise.race([this._map.get(pluginName).run(this._vier), timeout]).then(() => {
      result = this._map.get(pluginName);
    }).catch(() => {
      this._logger.error('Could not run plugin', { pluginName });
      this._map.delete(pluginName);
      result = null;
    });
    return result;
  }

  getAll() {
    return Array.from(this._map.values());
  }

  getPluginBasePath(pluginName) {
    return this._pluginPaths[pluginName];
  }

  /**
   * Includes an external script file and returns a promise.
   *
   * Note that this should only be used when you are forced to include scripts from external
   * servers, which cannot be included in your own plugin.
   *
   * @param url
   * @returns {Promise<any>}
   */
  async includeExternalResource(url) {
    return new Promise((resolve, reject) => {
      const tag = document.createElement('script');

      tag.onload = () => resolve(true);
      // @ts-ignore
      tag.onreadystatechange = () => resolve(true);
      tag.onerror = error => reject(error);
      tag.src = url;

      document.body.appendChild(tag);
    });
  }

  async init(): Promise<void> {
    if (this._isInitiated) {
      return;
    }

    this._currentBundle = defaultBundle;

    if (this._vier.config.plugins) {
      this._currentBundle = this._vier.config.plugins;
    }

    const isPluginWhitelisted = (pluginName) => {
      for (const whiteListedPluginName of [
        'pcweb/gui',
        'pcweb/email-editor',
        'pcweb/vertical-workspace-navigation',
        'pcweb/inbox',
        'pcweb/smart-assist',
        'parlamind/smart-assist',
        'pcweb/push-notifications',
        'pcweb/ccw-sabio-proxy',
        'multichannel/on-page',
        'integration/demo-standalone-smart-assist',
        'multichannel/salesforce-adapter',
        'ee856c15-f186-453a-a3d7-5411f577a0f0/surfly',
        'integration/demo-4insurance',
        'multichannel/teams-adapter',
        'multichannel/microsoft-dynamics-adapter',
        'multichannel/development-tools',
        'integration/dynamics-adapter-test',
        'integration/quickline-poc',
        'integration/callback-nonvoice-handler',
        'integration/swisslife-verdis-adapter',
        'integration/gkk-2fa-auth',
        'integration/display-meter-reading-input',
        'integration/advanced-browser',
        'integration/click-to-dial-post-message',
        'pcweb/hotkeys',
        'integration/strabag-crm-adapter',
        'integration/fix-agent-leave-chat',
        'multichannel/development-plugin-container',
        'integration/dynamics-oauth-connector',
        '4com/unified-desktop',
        'pcweb/protocol-handler',
        'multichannel/obm',
        'parlamind/email-editor',
        'lidl/salesforce-adapter',
        'pcweb/coaching',
        'ee856c15-f186-453a-a3d7-5411f577a0f0/search',
        'pcweb/mock-module',
        'kaufland/microsoft-dynamics-adapter',
        'haufe/salesforce-adapter',
        'pcweb/demo-app',
        'integration/gasag-nbb-nonvoice-statistics',
        'integration/collapsible-panels',
        'integration/extended-pick-list',
        'enbw/crm-adapter',
        'integration/automatic-call-answer-signal',
        'multichannel/sap-service-cloud-adapter',
        'viercom/unified-desktop'
      ]) {
        const simplifiedName = (pluginName !== 'multichannel/development-plugin-container') ?
          pluginName.substring(0, pluginName.lastIndexOf('/')) : pluginName;

        if (simplifiedName === whiteListedPluginName) {
          return true;
        }
      }
      return false;
    };

    const versionedPlugins = [];
    this._currentBundle = this._currentBundle.filter(plugin => {
      const pluginName = plugin?.name || '';
      if (plugin.loaderVersion === undefined) {
        if (!isPluginWhitelisted(pluginName)) {
          this._logger.error('Plugin ' + pluginName + ' should be using the new Loader');
        }
        return true;
      }
      versionedPlugins.push(plugin);
      return false;
    });

    // this._registerModules();
    await this._loadAndRunPlugins(this._currentBundle);

    let pluginLoader = new PluginLoader(versionedPlugins, this._vier.config, this._vier);
    pluginLoader.run();

    this._isInitiated = true;
  }

  public P = (pluginId) => {
    return this._map.get(pluginId);
  }

  async remove(pluginName) {
    this._logger.traceCall('remove', { pluginName });

    try {
      await this._map.get(pluginName).stop();
    } catch (e) {
      this._logger.error('Stopped plugin did not shut down gracefully', { pluginName, e });
    }

    this._map.delete(pluginName);
  }
}

export default new Plugins();
