/**
 * Checks whether or not the property is private (as indicated by an underscore in front of it).
 *
 * This will currently bypass itself if we're in a test environment.
 * @todo The plugin manager currently still uses SystemJS which accesses '__es6Module' and breaks this.
 * Once SystemJS is removed we can only check for "begins with _".
 * @todo There are tests depending on the access of private properties. These tests need to be updated to public APIs.
 *
 * @param {string} propertyName
 * @returns {boolean}
 */
const isPrivate = propertyName =>
  propertyName !== '_reactFragment' &&
  propertyName[0] === '_' &&
  propertyName[1] !== '_' &&
  !process.env.JEST_WORKER_ID;

/**
 * Wraps an object with a Proxy that controls access to its methods and properties, and makes sure everything stays
 * within the scope of the object, even when individual methods get passed around a React application.
 *
 * It is important to note that this will only act like a gate keeper at the moment, as it will bind the underlying
 * methods (including getters and setters) to the scope of the original object, *not* the proxy. This means that
 * subsequent method calls within the object will not run through the proxy. This could be changed easily by changing
 * the scope from "target" to "receiver" in the Reflection calls below, but destroys our access check mechanism. This is
 * due to the fact that we cannot know whether the object itself or some outside logic is trying to access private
 * methods or properties. Currently this would basically lock out the object from itself.
 *
 * @param object
 * @returns {*}
 */
export function createProxy(object) {
  return new Proxy(object, {
    /**
     *
     * @param {{}} target
     * @param {string} propertyName
     * @throws {SdkAccessProxyError} When trying to access private properties or methods (e.g. target._something)
     * @returns {*}
     */
    get(target, propertyName, _receiver) {
      // const targetName = target.constructor.name;
      const targetGetProperty = Reflect.get(target, propertyName, target);

      if (isPrivate(propertyName)) {
        // Turns out this breaks the salesforce adapter.. great stuff.
        //
        // throw new SdkAccessProxyViolationError(
        //     `Private property ${targetName}.${propertyName} must not be accessed directly`
        // );
        // eslint-disable-next-line
        // console.warn(
        //   `Private property ${targetName}.${String(propertyName)} must not be accessed directly`,
        // );
      }

      if (typeof targetGetProperty === 'undefined' && typeof target.__get === 'function') {
        // The property is not defined, but there is a magic getter we can use.
        return Reflect.apply(target.__get, target, [propertyName]);
      }

      if (typeof targetGetProperty === 'function') {
        return (...args) => {
          const result = Reflect.apply(targetGetProperty, target, args);
          // @todo Maybe we can enable this one day, we'll need to enable filtering first though, or else we'll break the laws of physics.
          // sdk.debug(target.constructor.name).traceCall(propertyName);
          return result;
        };
      }

      return targetGetProperty;
    },

    /**
     *
     * @param {{}} target
     * @param {string} propertyName
     * @param {*} value
     * @throws {SdkAccessProxyError} When trying to write to a private property or method (e.g. target._something)
     * @returns {boolean}
     */
    set(target, propertyName, value) {
      // const targetName = target.constructor.name;

      if (isPrivate(propertyName)) {
        // throw new SdkAccessProxyViolationError(
        //     `Private property ${targetName}.${propertyName} must not be set directly`
        // );
        // eslint-disable-next-line
        // console.warn(
        //   `Private property ${targetName}.${String(propertyName)} must not be set directly`,
        // );
      }

      Reflect.set(target, propertyName, value);

      return true;
    },
  });
}

export default createProxy;
