/**
 * Communication handler class that is responsible to register a postMessage event handler
 * and tries to resolve the originating plugin container to reroute the incoming messages
 * to the PluginContainer in charge.
 *
 * Note that this class is not responsible to actually handle the incoming messages, it
 * simply offloads the event handling from the main plugin package.
 *
 */
export default class Communication {
    _parent;

    constructor(parent) {
      this._parent = parent;

      // Bind because argh.
      this._handleContainerMessage = this._handleContainerMessage.bind(this);

      window.addEventListener('message', this._handleContainerMessage, false);
    }

    stop() {
      window.removeEventListener('message', this._handleContainerMessage, false);
    }

    _handleContainerMessage(e) {
      if (!e.data || e.data.type !== 'embed-api-command') {
        // This message cannot be handled, because it might not even be targeted at us.
        return;
      }

      // Find the iframe the message came from, so that we can resolve the actual plugin namespace.
      const frame = Array.from(document.querySelectorAll('iframe')).find(f => f.contentWindow === e.source);

      if (!frame) {
        // Couldn't find the originating frame.. this shouldn't happen, but who knows.
        return;
      }

      if (!this._parent.containers.has(frame.dataset.namespace)) {
        // Could not find the originating container.
        return;
      }

      // Relay the incoming message towards the dedicated PluginContainer so that it can be handled there.
      this._parent.containers.get(frame.dataset.namespace).handleMessage(e.data.command, e.data.payload);
    }
}