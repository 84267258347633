import { resolvePath } from './Common';

export default class PluginContainer {
    _plugin;

    _sdk;
    _manager;

    _container;

    _constructionPromise = { promise: null, resolve: null, reject: null };

    _registeredActions = [];
    _registeredEventCallbacks = new Map();
    _callbacks = new Map();

    get plugin() {
      return this._plugin;
    }

    get constructionPromise() {
      return this._constructionPromise.promise;
    }

    constructor(plugin, sdk, manager) {
      this._plugin = plugin;
      this._sdk = sdk;
      this._manager = manager;

      this._createContainer();
    }

    /**
     * This method handles messages that come out of the container.
     *
     */
    handleMessage(command, payload) {
      switch(command) {
      case 'embed-api-ready':
        // The API inside of the container is ready and setup, kick it off with the info
        // about the desired plugin that should be loaded and run. The container will then
        // respond with the "plugin-constructed" message below.
        this._sendMessage(
          'construct-plugin',
          { plugin: this._plugin }
        );
        break;

      case 'plugin-constructed':
        // The API inside of the container successfully set up and created the desired plugin,
        // the constructor of the plugin ran through and all is fine. We can now resolve the
        // constructionPromise to unblock the main thread as soon as all other loaded plugins
        // have been constructed as well.
        this._constructionPromise.resolve();
        break;

      case 'core.actions.register':
        // The plugin inside of the container wishes to register a custom action. This requires
        // us to wrap the new action in a hull from which we can relay any invokations on to the
        // actual callback inside of the container. We do that by simply pushing the "run-action"
        // command into the container, which causes the API inside of it to run the callback of
        // the plugin. The plugin itself does not need to care about any of these things, it simply
        // registers its actions and awaits their invokation - just as it would have done outside
        // of the container. The only limitation here of course is that it is only possible to use
        // serializable payloads (i.e. object, arrays, strings) - but not complex object structures.
        const { actionName } = payload;
        let qualifiedActionName = `${this._plugin.namespace}.${actionName}`;
        this._registeredActions.push(qualifiedActionName);

        try {
          // Note that we are registering the action under its fully qualified name, which
          // includes the namespace of the plugin. This automatically prevents any plugin from
          // scope-spoofing, as they cannot escape the namespace and scope they have been
          // granted by the plugin manager. This ultimately means: as a consumer of actions I
          // can trust that a specific action originates from the source I expect.

          this._sdk.actions.register(
            qualifiedActionName,
            (actionPayload) => {
              this._sendMessage(
                'run-action',
                {
                  actionName,
                  actionPayload
                }
              );
            }
          );
        } catch (e) {
          // Might wanna notify the plugin of this.
        }
        break;

      case 'core.actions.invoke':
        try {
          //console.log('invoking', payload);
          this._sdk.actions.invoke(payload.actionName, payload.actionPayload);
        } catch (e) {
          // ..
        }
        break;

      case 'core.events.on':
        if (this._registeredEventCallbacks.has(payload.eventName)) {
          // We already have an active event listener for this event, thus we can omit creating
          // another one. This prevents us from bloating the channels and impacting runtime
          // performance too much.
          return;
        }

        // Pretty much the same we did for the actions above: we create a hull listener for the desired
        // event and once it gets triggered, we push that info into the container. The container API will
        // then invoke all listeners the plugin has attached to this specific event.
        this._registeredEventCallbacks.set(
          payload.eventName,
          this._sdk.events.on(
            payload.eventName,
            (eventPayload) => {
              try {
                this._sendMessage(
                  'event-triggered',
                  {
                    eventName: payload.eventName,
                    eventPayload
                  }
                );
              } catch (e) {
                // Well, that didn't work out too well. Most likely the event payload couldn't be
                // cloned over to the container. Sadly many of our current events provide a complex
                // payload which cannot be serialized. To make things sorta-work we can skip the
                // payload for now.. but we probably should fix that event payload.
                this._sendMessage(
                  'event-triggered',
                  { eventName: payload.eventName }
                );
              }
            }
          )
        );
        break;

      case 'core.events.trigger':
        // The plugin inside of the container wishes to trigger a custom event to inform other parties of
        // the ecosystem something has happened. Events come with a name and an optional payload, but we
        // prefix the plugins event name with its own namespace to prevent scope-spoofing here as well,
        // just as with the actions above. This means no plugin can trigger events outside of its own
        // scope and thus I as a consumer of events am able to trust their origin. Plugins cannot escape
        // the namespace and scope granted by the plugin manager because this part of the API lays outside
        // of their reach (because they cannot escape their container).
        let qualifiedEventName = `${this._plugin.namespace}.${payload.eventName}`;
        this._sdk.events.trigger(qualifiedEventName, payload.eventPayload);
        break;

      case 'core.get':
        // do something.
        this._handleGetCommand(payload);
        break;

      case 'core.get-response':
        this._callbacks.get(payload.id)(payload.result);
        break;

      default:

      }
    }

    /**
     * Once all plugins have been created in their containers, the plugin manager will invoke this method
     * on every plugin container. This resembles the known flow: after all plugins are available, invoke
     * .run() on all plugins. We simply pass this on to our own container and let the container API do its
     * thing.
     *
     */
    run() {
      this._sendMessage('run-plugin');
    }

    async get(path) {
      // Create a Promise so that we can defer this method until a response is received.
      let resolve;
      //eslint-disable-next-line
      let reject;
      let deferred = new Promise((yay, ouchy) => {
        //eslint-disable-next-line
        resolve = yay; reject = ouchy;
      });

      // Create a handler for the response of this request.
      let id = Math.random().toString(16).slice(2);
      this._callbacks.set(id, (response) => resolve(response));

      this._sendMessage(
        'core.get',
        { id, path }
      );

      return deferred;
    }

    async _handleGetCommand(payload) {
      let result;
      // Break apart the provided path into the realm and the actual path. The realm may either be "core"
      // or "plugins", with the first one being an alias for the SDK and the latter being an alias for
      // the PluginContainers of the plugin manager.
      let [ realm, ...path ] = payload.path.split('.');

      if (realm === 'core') {
        // This tries to access core functionality of the SDK itself.
        result = await resolvePath(this._sdk, path);

      } else if (realm === 'plugins') {
        // This tries to access another plugin. As all plugins run in their own thread, we have to offload
        // resolving of the requested path to the actual plugin.
        let [ pluginScope, pluginName, ...pluginPath ] = path;
        result = await this._manager.containers.get(`${pluginScope}.${pluginName}`).get(pluginPath);
      }

      this._sendMessage(
        'get-response',
        {
          id: payload.id,
          result,
        }
      );
    }

    _createContainer() {
      // Create a dedicated iframe for this plugin that acts as an isolated container.
      this._container = document.createElement('iframe');
      this._container.className = 'plugin-container';
      this._container.dataset.namespace = this.plugin.namespace;
      this._container.style.display = 'none';

      // Guard the container against any harmful exploits. Note that we do not grant "same-origin" here,
      // thus the containers will be treated as if they are from another, random origin. Plugins won't
      // be able to access the parent window and thus cannot escape their own container.
      this._container.sandbox =
            'allow-forms ' +
            'allow-popups ' +
            'allow-scripts';

      document.body.appendChild(this._container);

      // Await the first message of the plugin.
      this._constructionPromise.promise = new Promise((resolve, reject) => {
        this._constructionPromise.reject = reject;
        this._constructionPromise.resolve = resolve;
      });

      this._container.src = '/plugin-container.html';
    }

    /**
     * Sends a message into the container.
     *
     */
    _sendMessage(command, payload = {}) {
      try {
        payload = JSON.stringify(payload);
      } catch (e) {
        // Could not stringify the payload, might be too complex.
        payload = null;
      }

      this._container.contentWindow.postMessage(
        {
          type: 'container-command',
          command,
          payload
        },
        '*'
      );
    }

    destroy() {
      // Clear up the created iframe.
      this._container.remove();

      // Clear up all registered actions.
      this._registeredActions.forEach(actionName => this._sdk.actions.remove(actionName));

      // Remove all attached event listeners.
      for (let listener in this._registeredEventCallbacks) {
        this._sdk.events.off(listener);
      }
    }
}